import React from 'react';

import Layout from '../../components/Layout/layout';
import Equipament from '../../components/Equipament/equipament'
import SEO from '../../components/seo';

function equipamentPage() {
  return (
        <Layout>
            <SEO title="Equipament"/>
            <Equipament/>            
        </Layout>
    )
}

export default equipamentPage;
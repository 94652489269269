import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import './equipament.css'
import BoxModel from '../BoxModel/index'

export default () => {
  const data = useStaticQuery(graphql`
  query {
    
    superiorCoffee: file(relativePath: {eq: "chantilly-bottle.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gasIsi: file(relativePath: {eq: "gas-isi.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gasBest: file(relativePath: {eq: "gas-best.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    chantillySpout: file(relativePath: {eq: "chantilly-spout.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    filtroBunn: file(relativePath: {eq: "filtro-bunn.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    blender: file(relativePath: {eq: "blender.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     refreshment: file(relativePath: {eq: "refreshment.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    chocolateMachine: file(relativePath: {eq: "chocolate-machine.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    chocolateMachine5: file(relativePath: {eq: "chocolate-machine5l.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    drawer: file(relativePath: {eq: "drawer.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tamper: file(relativePath: {eq: "tamper.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tamperSupport: file(relativePath: {eq: "tamper-support.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sprinkler: file(relativePath: {eq: "sprinkler.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pitcher20oz: file(relativePath: {eq: "pitcher20oz.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pitcher12oz: file(relativePath: {eq: "pitcher12oz.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pitcher3oz: file(relativePath: {eq: "pitcher3oz.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    thermometer: file(relativePath: {eq: "thermometer.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    acapulcoGlass: file(relativePath: {eq: "acapulco-glass.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    billGlass: file(relativePath: {eq: "bill-glass.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    baristaGlass: file(relativePath: {eq: "barista-glass.png"}) {
      childImageSharp {
        fixed(quality:100, width: 300, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)
  return (
      <section className="equipament-page">
          <div className="equipament-container">
            <BoxModel
              title="Garrafas de chantilly"
              description="500ml"
              data={data.superiorCoffee.childImageSharp.fixed}/>
            <BoxModel
              title="Gas para chantilly Isi"
              description="10 Unidades"
              data={data.gasIsi.childImageSharp.fixed}/>
            <BoxModel
              title="Gas para chantilly Best"
              description="10 Unidades"
              data={data.gasBest.childImageSharp.fixed}/>
            <BoxModel
              title="Bico decorador"
              data={data.chantillySpout.childImageSharp.fixed}/>
            <BoxModel
              title="Filtro para Máquina Bunn"
              description="Tipo vp17"
              data={data.filtroBunn.childImageSharp.fixed}/>
            <BoxModel
              title="Liquidificador Drink Machine Vitamix"
              data={data.blender.childImageSharp.fixed}/>
            <BoxModel
              title="Refresqueira Compact 2x8l Bras"
              data={data.refreshment.childImageSharp.fixed}/>
            <BoxModel
              title="Chocolateira ACS 3L Bras"
              data={data.chocolateMachine.childImageSharp.fixed}/>
            <BoxModel
            title="Chocolateira ACS 5L Bras"
            data={data.chocolateMachine5.childImageSharp.fixed}/>
            <BoxModel
              title="Gaveta para Borra Inox"
              data={data.drawer.childImageSharp.fixed}/>
            <BoxModel
              title="Tamper"
              description="58mm"
              data={data.tamper.childImageSharp.fixed}/>
            <BoxModel
              title="Porta Tamper"
              data={data.tamperSupport.childImageSharp.fixed}/>
            <BoxModel
              title="Polvilhador Tela Fina"
              data={data.sprinkler.childImageSharp.fixed}/>
            <BoxModel
              title="Pitcher 20 oz"
              data={data.pitcher20oz.childImageSharp.fixed}/>
            <BoxModel
              title="Pitcher 12 oz"
              data={data.pitcher12oz.childImageSharp.fixed}/>
            <BoxModel
              title="Pitcher Artistica 3 oz"
              data={data.pitcher3oz.childImageSharp.fixed}/>
            <BoxModel
              title="Termômetro para medição de leite"
              data={data.thermometer.childImageSharp.fixed}/>
            <BoxModel
              title="Caneca Acapulco"
              description="280ml"
              data={data.acapulcoGlass.childImageSharp.fixed}/>
            <BoxModel
              title="Taça Bill"
              description="240ml e 120ml "
              data={data.billGlass.childImageSharp.fixed}/>
            <BoxModel
              title="Copo Barista"
              description="220ml"
              data={data.baristaGlass.childImageSharp.fixed}/>
            
          </div>
      </section>
  );
}
